<template lang="pug">
	.d-inline-block
		img.table-loader(v-if='isDarkColor' src='/assets/img/current/loader-dark.svg')
		img.table-loader(v-else src='/assets/img/current/loader.svg')
</template>

<script>
export default {
    name: 'Loader',
    props: {},
    data: () => ({}),
    computed: {},
    created() {},
    methods: {},
};
</script>

<style scoped lang="scss"></style>
